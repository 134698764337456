export default function changeColorOnScroll() {
    const heroSection = document.querySelector('.heroMedia');
    const textColorElements = document.querySelectorAll('.text-colorVariant');
    const buttonColor = document.querySelectorAll('.button-colorVariant');
    const logoBlue = document.querySelectorAll('.logoBlue');
    const logoWhite = document.querySelectorAll('.logoWhite');

    function updateColor() {
        if(heroSection) {
            const heroRect = heroSection.getBoundingClientRect();

            if (heroRect.top < window.innerHeight && heroRect.bottom >= 0) {
                // La section hero est visible dans la fenêtre
                textColorElements.forEach((item) => {
                    item.style.color = 'white'; // Changer la couleur selon vos besoins
                })
                buttonColor.forEach((item) => {
                    item.classList.add('white'); // Changer la couleur selon vos besoins
                })
            } else {
                textColorElements.forEach((item) => {
                    item.style.color = ''; // Changer la couleur selon vos besoins
                })
                buttonColor.forEach((item) => {
                    item.classList.remove('white'); // Changer la couleur selon vos besoins
                })
            }
        }
    }

    function updateLogo() {
        if(heroSection) {
            const heroRect = heroSection.getBoundingClientRect();

            if (heroRect.top < window.innerHeight && heroRect.bottom >= 0) {
                // La section hero est visible dans la fenêtre
                logoWhite.forEach((item) => {
                    item.classList.remove('hidden');
                })
                logoBlue.forEach((item) => {
                    item.classList.add('hidden');
                })
            } else {
                logoBlue.forEach((item) => {
                    item.classList.remove('hidden');
                })
                logoWhite.forEach((item) => {
                    item.classList.add('hidden');
                })
            }
        }
    }

    // Écouter l'événement de défilement
    window.addEventListener('scroll', updateColor);
    window.addEventListener('scroll', updateLogo);

    // Appeler la fonction une fois au chargement de la page pour vérifier la couleur initiale
    updateColor();
    updateLogo()
}
