import accessibleMenu from "accessible-menu";

export function fixedNavbar() {
    let header              = document.getElementById('mainHeader');
    let mainHeaderMenu              = document.getElementById('mainHeaderMenu');
    let distanceAfterSticky = 800;
    let scrollPage          = document.documentElement.scrollTop;
    let logoWhite                = document.getElementById('logoWhite');
    let logoDark                = document.getElementById('logoDark');


    if(scrollPage > 180) {
        header.classList.add('is-sticky');
        mainHeaderMenu.classList.remove('py-32');
        mainHeaderMenu.classList.add('py-16');
        logoWhite.classList.add('hidden')
        logoDark.classList.remove('hidden')
    } else {
        header.classList.remove('is-sticky');
        mainHeaderMenu.classList.add('py-32');
        mainHeaderMenu.classList.remove('py-16');
        logoWhite.classList.remove('hidden')
        logoDark.classList.add('hidden')
    }

}

export function openMobileMenu() {
    let burgerMenu          = document.getElementById('burger-menu');
    let mainMenu            = document.getElementById('mobileMenu');

    burgerMenu.addEventListener('click', function () {
        if (mainMenu.classList.contains('is-open')) {
            document.body.classList.remove('fixed', 'w-full')

        } else {
            document.body.classList.add('fixed', 'w-full')
            mainMenu.classList.add('is-open')

        }
    })
}

export function openSubmenu() {
     let arrows         = Array.from(document.querySelectorAll('.arrow-submenu'));
     let backMenu       = Array.from(document.querySelectorAll('.return-back'));
     let subMenuWrapper = document.getElementById('sub-menu-item');

    arrows.forEach((item, index) => {
        item.addEventListener('click', function () {
            let ID      = item.getAttribute('id');
            let subMenu = document.getElementById('item-' + ID);

            subMenu.classList.add('is-open');
        })
    });

    backMenu.forEach((item, index) => {
        item.addEventListener('click', function () {
            let ID      = item.getAttribute('id');
            let FinalID = ID.replace('back-', '')
            let subMenu = document.getElementById('item-submenu-' + FinalID);
            subMenu.classList.remove('is-open');
        })
    });

}

export function closeMenu() {
    let closeMenu  = document.querySelectorAll('.close-burger');
    let navMobile = document.getElementById('mobileMenu');
    let subMenus = document.querySelectorAll('.wrapperSubmenuMobile');

    closeMenu.forEach((item, index) => {
        item.addEventListener('click', function () {
            subMenus.forEach((item, index) => {
                item.classList.remove('is-open');
            });
            navMobile.classList.remove('is-open');
            document.body.classList.remove('fixed', 'w-full')
        });
    });


}

export function openDesktopSubmenu() {
    const hasChild = document.querySelectorAll('.has-children')
    const mainHeader = document.getElementById('mainHeader');
    const mainHeaderMenu = document.getElementById('mainHeaderMenu');
    const logoWhite = document.getElementById('logoWhite');
    const logoDark = document.getElementById('logoDark');

    let currentMegaMenu = null;
    let leaveTimeout;
    let overlay = document.getElementById('overlay');
    hasChild.forEach(hasChild => {
        let megaMenu = document.getElementById('megaMenu-' + hasChild.id);
        hasChild.addEventListener('mouseenter', function () {
            mainHeaderMenu.classList.add('has-mega-menu');
            logoWhite.classList.add('hidden')
            logoDark.classList.remove('hidden')
            overlay.classList.remove('opacity-0')
            overlay.classList.remove('hidden')
            overlay.classList.add('opacity-100')
            // Fermer le méga-menu actuellement ouvert
            if (currentMegaMenu && currentMegaMenu !== megaMenu) {
                currentMegaMenu.classList.remove('is-open');
                logoWhite.classList.remove('hidden')
                logoDark.classList.add('hidden')
            }

            megaMenu.classList.add('is-open');
            currentMegaMenu = megaMenu;
        });

        hasChild.addEventListener('mouseleave', function () {
            // Utiliser un délai avant de fermer le méga-menu
            leaveTimeout = setTimeout(() => {
                megaMenu.classList.remove('is-open');
                mainHeaderMenu.classList.remove('has-mega-menu');
                overlay.classList.add('opacity-0')
                overlay.classList.remove('opacity-100')
                overlay.classList.add('hidden')
                if(!mainHeader.classList.contains('is-sticky')) {
                    logoWhite.classList.remove('hidden')
                    logoDark.classList.add('hidden')
                }
            }, 300);
        });

        megaMenu.addEventListener('mouseenter', function () {
            // Mettre à jour currentMegaMenu lorsque la souris entre dans le méga-menu
            currentMegaMenu = megaMenu;
            // Annuler le délai de fermeture si la souris entre dans le méga-menu
            clearTimeout(leaveTimeout);
        });

        megaMenu.addEventListener('mouseleave', function () {
            // Mettre à jour currentMegaMenu lorsque la souris quitte le méga-menu
            currentMegaMenu = null;
            // Utiliser un délai avant de fermer le méga-menu
            overlay.classList.add('opacity-0')
            overlay.classList.remove('opacity-100')
            overlay.classList.add('hidden')
            leaveTimeout = setTimeout(() => {
                megaMenu.classList.remove('is-open');
                mainHeaderMenu.classList.remove('has-mega-menu');
                if(!mainHeader.classList.contains('is-sticky')) {
                    logoWhite.classList.remove('hidden')
                    logoDark.classList.add('hidden')
                }
            }, 300);
        });

        // Gestion des éléments à l'intérieur du méga-menu
        const megaMenuLinks = megaMenu.querySelectorAll('.mega-menu-link');

        megaMenuLinks.forEach(link => {
            link.addEventListener('mouseenter', function () {
                // Fermer le méga-menu actuellement ouvert
                if (currentMegaMenu && currentMegaMenu !== megaMenu) {
                    currentMegaMenu.classList.remove('is-open');
                    mainHeaderMenu.classList.remove('has-mega-menu');
                    overlay.classList.remove('opacity-0')
                    overlay.classList.add('opacity-100')
                    overlay.classList.remove('hidden')
                    logoWhite.classList.remove('hidden')
                    logoDark.classList.add('hidden')
                }

                // Mettre à jour currentMegaMenu
                currentMegaMenu = megaMenu;
            });
        });
    });

    mainHeader.addEventListener('mouseleave', function (e) {
        const megaMenus = document.querySelectorAll('.megaMenu');

        megaMenus.forEach((megaMenu) => {
            megaMenu.classList.remove('is-open');
            overlay.classList.add('opacity-0')
            mainHeaderMenu.classList.remove('has-mega-menu');
            if(!mainHeader.classList.contains('is-sticky')) {
                logoWhite.classList.remove('hidden')
                logoDark.classList.add('hidden')
            }
        });
    });

}

export function scrollPage() {
    document.addEventListener('scroll', fixedNavbar)
}


export default {fixedNavbar, openMobileMenu, openDesktopSubmenu, closeMenu}




const mobileMenuItems = document.querySelectorAll('#mobileMenu .dropdown a[aria-haspopup="true"]');
mobileMenuItems.forEach((item) => {
    item.addEventListener('click', ev => {
        ev.preventDefault();
        ev.stopPropagation();
        item.href = item.dataset.href;
    })
})

