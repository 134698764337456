export default function scrollReveal()
{
    let revealPoint = 100
    const revealElement = document.querySelectorAll(".scrollreveal")
    if(revealElement) {
        for (var i = 0; i < revealElement.length; ++i) {
            const windowHeight = window.innerHeight
            const revealTop = revealElement[i].getBoundingClientRect().top
            if (revealTop < windowHeight - revealPoint) {
                revealElement[i].classList.add("isreveal")
                revealElement[i].classList.remove("scrollreveal")
            } else {
                revealElement[i].classList.remove("isreveal")
                revealElement[i].classList.add("scrollreveal")
            }
        }
    }

    const revealHomeHeroImg = document.querySelectorAll(".homeHeroImage")
    for (var i = 0; i < revealHomeHeroImg.length; ++i) {
        revealPoint = 0
        const windowHeight = window.innerHeight
        const revealTop = revealHomeHeroImg[i].getBoundingClientRect().top
        if (revealTop < windowHeight - revealPoint) {
            revealHomeHeroImg[i].classList.add("isreveal")
        } else {
            revealHomeHeroImg[i].classList.remove("isreveal")
        }
    }

    const revealHome = document.querySelectorAll(".heroAnimation")
    for (var i = 0; i < revealHome.length; ++i) {
        revealHome[i].classList.add("isreveal")
        revealHome[i].classList.remove("heroAnimation")
    }
}
