export default function readMore() {
  let readMoreBtns = document.querySelectorAll('.btnReadMore');

  if(readMoreBtns) {
    readMoreBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        // Récupérer les éléments de texte pour cette carte
        const card = btn.closest('.testimonial-card');
        // Rechercher dots dans la card
        const dots = card.querySelector('#dots');
        console.log('card', card)
        console.log('dots', dots)
        const moreText = card.querySelector('#more');

        // Afficher ou cacher le texte complet
        if (dots.style.display === "none") {
          dots.style.display = "inline";
          btn.textContent = "Voir plus";
          moreText.style.display = "none";
        } else {
          dots.style.display = "none";
          btn.textContent = "Voir moins";
          moreText.style.display = "inline";
        }
      });
    });
  }

}
